import dayjs from 'dayjs'

export function caseDetailMethods(t, caseDetailMode, caseDetail) {
  const removeInputIdCode = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.customId = ''
  }
  const removeInputRocId = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.rocId = ''
  }
  const removeInputCardNum = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.cardId = ''
  }
  const removeInputMyKadId = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    caseDetail.value.myKadId = ''
  }

  const getEPassLocalTime = () => {
    if (caseDetail.value.ePassIssued) {
      const str = t('LM001.ePassStatusTrue')
      const strDate = dayjs(caseDetail.value.ePassIssued).format('YYYY-MM-DD')
      return `${str}(${strDate})`
    } else {
      return t('LM001.ePassStatusFalse')
    }
  }

  return {
    removeInputIdCode,
    removeInputRocId,
    removeInputCardNum,
    removeInputMyKadId,
    getEPassLocalTime,
  }
}
