import { computed } from 'vue'

export function useComputed(webConfig, caseDetailMode) {
  const isShowLinkToMeasurementsPeriod = computed(() => {
    let retData = true
    webConfig.value.forEach((conf) => {
      if (conf.id === 'health_data' && conf.privilege.show === false) {
        retData = false
      }
    })
    return retData
  })
  const isWebConfigEnableName = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'name') {
              return caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigEnableCustomId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'CUSTOM_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableCardId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'NFC_CARD_NUMBER') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableRocId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'ROC_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableMobilePhoneId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'MOBILE_PHONE') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnableMyKadId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (
            webConfig.value[i].idTypes[j].key === 'MY_KAD_ID' ||
            webConfig.value[i].idTypes[j].key === 'My_Kad_ID'
          ) {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigEnablePhoneNumber = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'mobile') {
              return caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigEnableGroup = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'group') {
              return caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowName = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'name') {
              return caseColumn[j].privilege.show
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowCustomId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'CUSTOM_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowCardId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'NFC_CARD_NUMBER') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowRocId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'ROC_ID') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowMobilePhoneId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (webConfig.value[i].idTypes[j].key === 'MOBILE_PHONE') {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowMyKadId = computed(() => {
    let checkIdTypeConfig = false
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (webConfig.value[i].idTypes && webConfig.value[i].idTypes.length) {
        for (let j = 0; j < webConfig.value[i].idTypes.length; j++) {
          if (
            webConfig.value[i].idTypes[j].key === 'MY_KAD_ID' ||
            webConfig.value[i].idTypes[j].key === 'My_Kad_ID'
          ) {
            checkIdTypeConfig = true
          }
        }
      }
    }
    if (checkIdTypeConfig === false) {
      // 找不到此設定,代表無此欄位權限
      return false
    }
    return true
  })
  const isWebConfigShowPhoneNumber = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'mobile') {
              return caseColumn[j].privilege.show
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowGroup = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'group') {
              return caseColumn[j].privilege.show
            }
          }
        }
      }
    }
    return true
  })
  const isWebConfigShowEPass = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'vas_epass' &&
        webConfig.value[i].privilege.show === true
      ) {
        console.log('isWebConfigShowEPass', webConfig.value[i].privilege.show)
        return true
      }
    }
    console.log('isWebConfigShowEPass', false)
    return false
  })
  const isEnableCreateCase = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'create_subject') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isEnableGroupManagement = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'group_mgnt') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isEnableCaseEdit = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'subject_edit') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isEnableCaseDelete = computed(() => {
    for (let i = 0; i < webConfig.value.length > 0; i++) {
      if (
        webConfig.value[i].id === 'subject_management' &&
        webConfig.value[i].privilege.show === true
      ) {
        const caseColumn = webConfig.value[i].content
        if (caseColumn) {
          for (let j = 0; j < caseColumn.length; j++) {
            if (caseColumn[j].id === 'subject_delete') {
              return !caseColumn[j].privilege.enable
            }
          }
        }
      }
    }
    return true
  })
  const isShowEligibilityStatus = computed(() => {
    if (caseDetailMode.value === 'MODIFY') {
      return true
    }
    return false
  })
  const isShowEPassStatus = computed(() => {
    if (caseDetailMode.value === 'MODIFY') {
      return true
    }
    return false
  })

  return {
    isShowLinkToMeasurementsPeriod,
    isWebConfigEnableName,
    isWebConfigEnableCustomId,
    isWebConfigEnableCardId,
    isWebConfigEnableRocId,
    isWebConfigEnableMobilePhoneId,
    isWebConfigEnableMyKadId,
    isWebConfigEnablePhoneNumber,
    isWebConfigEnableGroup,
    isWebConfigShowName,
    isWebConfigShowCustomId,
    isWebConfigShowCardId,
    isWebConfigShowRocId,
    isWebConfigShowMobilePhoneId,
    isWebConfigShowMyKadId,
    isWebConfigShowPhoneNumber,
    isWebConfigShowGroup,
    isWebConfigShowEPass,
    isEnableCreateCase,
    isEnableGroupManagement,
    isEnableCaseEdit,
    isEnableCaseDelete,
    isShowEligibilityStatus,
    isShowEPassStatus,
  }
}
