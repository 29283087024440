import dayjs from 'dayjs'

export function convertMethods(t, measureThreshold) {
  const convertCardId = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].idType === 'NFC_CARD_NUMBER') {
          return data[i].idValue
        }
      }
    }
    return ''
  }
  const convertRocId = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].idType === 'ROC_ID') {
          return data[i].idValue
        }
      }
    }
    return ''
  }
  const convertMobilePhoneId = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].idType === 'MOBILE_PHONE') {
          return data[i].idValue
        }
      }
    }
    return ''
  }
  const convertMyKadId = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].idType === 'My_Kad_ID' || data[i].idType === 'MY_KAD_ID') {
          return data[i].idValue
        }
      }
    }
    return ''
  }
  const convertTimeFormat = (text) => {
    if (text) {
      return dayjs(text).format('YYYY/MM/DD')
    } else {
      return ''
    }
  }
  const convertTimeFormatType2 = (text) => {
    if (text) {
      return dayjs(text).format('YYYY/MM/DD')
    } else {
      return t('LM001.validityPeriodNone')
    }
  }
  const convertEpass = (text) => {
    if (text) {
      return t('LM001.calimed') + '(' + dayjs(text).format('YYYY/MM/DD') + ')'
    } else {
      return t('LM001.notReceived')
    }
  }
  const convertGender = (text) => {
    if (text === 'M') {
      return t('LM001.male')
    } else if (text === 'F') {
      return t('LM001.female')
    } else {
      return ''
    }
  }
  const convertRemark = (text) => {
    if (text) {
      return text.length > 11 ? text.substr(0, 11 - 1) + '...' : text
    } else {
      return text
    }
  }
  const convertHeight = (text) => {
    if (
      measureThreshold.value?.stature?.unit === 'ft&in' &&
      typeof text === 'string'
    ) {
      return text.replace('&', "'") + '"'
    } else {
      return text
    }
  }
  const convertState = (text) => {
    if (text === 'SUSPENDED') {
      return t('LM001.suspended')
    } else if (text === 'ACTIVATED') {
      return t('LM001.activate')
    } else {
      return text
    }
  }

  return {
    convertCardId,
    convertRocId,
    convertMobilePhoneId,
    convertMyKadId,
    convertTimeFormat,
    convertTimeFormatType2,
    convertEpass,
    convertGender,
    convertRemark,
    convertHeight,
    convertState,
  }
}
