<template>
  <div class="case-list-container">
    <div class="case-list-area">
      <div class="case-list-top">
        <div class="new-case-btn">
          <a-button @click="clickCreateCase" :disabled="isEnableCreateCase">
            <template #icon><UserAddOutlined /></template>
            {{ $t('LM001.createCase') }}
          </a-button>
        </div>
        <!-- <div class="import-btn">
          <a-button>
            <template #icon><CloudUploadOutlined /></template>
            {{ $t('LM001.caseImport') }}
          </a-button>
        </div>
        <div class="example-btn">
          <a-button>
            <template #icon><CloudDownloadOutlined /></template>
            {{ $t('LM001.formExample') }}
          </a-button>
        </div> -->
        <div class="group-btn">
          <a-button
            @click="goToGroupManagement"
            :disabled="isEnableGroupManagement"
          >
            <template #icon><TeamOutlined /></template>
            {{ $t('LM001.groupManagerment') }}
          </a-button>
        </div>
        <div class="filter-btn">
          <a-button
            :class="filterStatus === 'Activate' ? 'check-btn' : 'uncheck-btn'"
            @click="onFilter('Activate')"
          >
            <template v-if="filterStatus === 'Activate'" #icon>
              <CheckCircleOutlined />
            </template>
            {{ $t('LM001.activate') }}
          </a-button>
          <a-button
            :class="filterStatus === 'Suspended' ? 'check-btn' : 'uncheck-btn'"
            @click="onFilter('Suspended')"
          >
            <template v-if="filterStatus === 'Suspended'" #icon>
              <CheckCircleOutlined />
            </template>
            {{ $t('LM001.suspended') }}
          </a-button>
          <a-button
            :class="filterStatus === 'All' ? 'check-btn' : 'uncheck-btn'"
            @click="onFilter('All')"
          >
            <template v-if="filterStatus === 'All'" #icon>
              <CheckCircleOutlined />
            </template>
            {{ $t('LM001.all') }}
          </a-button>
        </div>
        <div class="search-btn">
          <a-button class="confirm-button" @click="onClickSearch">
            {{ $t('HR001.query') }}
          </a-button>
        </div>
        <div class="search-condition">
          <GroupSelector
            v-if="searchType === 2"
            style="width: 250px"
            :groups="groups"
            v-model:selectedGroups="selectedGroups"
          />
          <!-- <a-select
            v-if="searchType === 2"
            v-model:value="selectedGroupIds"
            style="width: 250px"
            mode="multiple"
            :placeholder="t('LM001.selectedGroup')"
            :max-tag-count="2"
            @focus="handleSearchGroupFocus"
            @change="handleSelectGroupChange"
            :open="false"
            :max-tag-placeholder="maxTagPlaceholderForGroup"
            :tag-render="tagRenderForGroup"
          >
            <a-select-option
              v-for="group in groups"
              :key="group.groupId"
              :value="group.groupId"
            >
              {{ group.title }}
            </a-select-option>
          </a-select> -->
          <a-input
            v-else
            style="width: 250px"
            v-model:value="searchName"
            :placeholder="t('HR001.chooseName')"
          />
        </div>
        <div class="search-type">
          <a-radio-group v-model:value="searchType">
            <a-radio :value="1">{{ $t('LM001.name') }}</a-radio>
            <a-radio :value="2">{{ $t('LM001.group') }}</a-radio>
          </a-radio-group>
        </div>
      </div>
      <div class="case-list-table">
        <a-collapse
          v-if="searchMode === 'Group'"
          v-model:activeKey="searchGroupActiveKey"
          accordion
          @change="handleGroupCollapseChange"
        >
          <a-collapse-panel
            v-for="group in selectedGroups"
            :key="group.groupId"
            :header="group.title"
          >
            <CaseTable
              :ref="(el) => setGroupCaseTableRef(el, group.groupId)"
              :columns="columns"
              :filterStatus="filterStatus"
              :searchMode="searchMode"
              :searchName="searchName"
              :searchGroupId="group.groupId"
              @openCaseDetail="clickOpenCaseDetail"
              @activateSuspend="clickActivateSuspend"
              @deleteCase="clickDeleteCase"
            />
          </a-collapse-panel>
        </a-collapse>
        <CaseTable
          v-else
          ref="caseTableComponent"
          :columns="columns"
          :filterStatus="filterStatus"
          :searchMode="searchMode"
          :searchName="searchName"
          :searchGroupId="searchGroupId"
          @openCaseDetail="clickOpenCaseDetail"
          @activateSuspend="clickActivateSuspend"
          @deleteCase="clickDeleteCase"
        />
        .
      </div>
    </div>
    <div class="case-modal-area">
      <a-modal
        class="case-case-detail-modal"
        v-model:visible="isShowCaseDetail"
        :title="caseInfoModalTitle"
        :closable="false"
        width="750px"
      >
        <template #footer>
          <div v-if="caseDetailMode == 'INFO'">
            <a-button
              class="btn-confirm-green"
              key="modify"
              @click="clickStartModifyMode"
            >
              {{ $t('LM001.edit') }}
            </a-button>
          </div>
          <div v-if="caseDetailMode == 'MODIFY'">
            <a-button
              class="btn-cancel-gray"
              key="cancel"
              @click="clickCancelModify"
            >
              {{ $t('LM001.cancel') }}
            </a-button>
            <a-button
              class="btn-confirm-green"
              key="submit"
              @click="clickSaveModify"
              :loading="isSaveloading"
            >
              {{ $t('LM001.save') }}
            </a-button>
          </div>
          <div v-if="caseDetailMode == 'CREATE'">
            <a-button
              class="btn-cancel-gray"
              key="cancel"
              @click="clickCancelModify"
            >
              {{ $t('LM001.cancel') }}
            </a-button>
            <a-button
              class="btn-confirm-green"
              key="submit"
              @click="clickSaveCreate"
              :loading="isSaveloading"
            >
              {{ $t('LM001.save') }}
            </a-button>
          </div>
        </template>
        <a-form
          ref="caseFormRef"
          :model="caseDetail"
          :label-col="caseDetailLabelCol"
          :wrapper-col="caseDetailWrapperCol"
          :rules="caseDetailRules"
        >
          <!-- Personal Info-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.personalInfoArea') }}
            </div>
            <a-form-item
              :label="$t('LM001.name')"
              name="name"
              v-if="isWebConfigShowName"
            >
              <a-input
                v-model:value="caseDetail.name"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
              />
            </a-form-item>
            <a-form-item :label="$t('LM001.gender')" name="gender">
              <a-select
                :allowClear="true"
                v-model:value="caseDetail.gender"
                :placeholder="$t('LM001.pleaseChooseGender')"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
              >
                <a-select-option key="M" value="M">
                  {{ $t('LM001.male') }}
                </a-select-option>
                <a-select-option key="F" value="F">
                  {{ $t('LM001.female') }}
                </a-select-option>
              </a-select>
            </a-form-item>
            <a-form-item :label="$t('LM001.birthday')" name="birthday">
              <a-date-picker
                v-model:value="caseDetailBirthday"
                :format="birthdayDateFormat"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
              />
            </a-form-item>
            <a-form-item :label="$t('LM001.height')" name="height">
              <a-input
                v-model:value="caseDetail.height"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :suffix="getHeightUnit()"
                :placeholder="getHeightPlaceholder()"
              />
            </a-form-item>
          </a-card>
          <!-- ID Info-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.idInfoArea') }}
            </div>
            <div v-if="!isWebConfigShowEPass" class="first-card-warning">
              {{
                isShowCustomIdCardIdRocIdWarning
                  ? $t('LM001.pleaseInputCustomIdCardIdRocId')
                  : ' '
              }}
            </div>
            <a-form-item
              :label="$t('LM001.idCode')"
              name="customId"
              v-if="isWebConfigEnableCustomId"
            >
              <a-input
                v-model:value="caseDetail.customId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.idHint')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputIdCode()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.rocId')"
              name="rocId"
              v-if="isWebConfigShowRocId"
            >
              <a-input
                v-model:value="caseDetail.rocId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.rocIdHint')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputRocId()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.cardNum')"
              name="cardId"
              v-if="isWebConfigShowCardId"
            >
              <a-input
                v-model:value="caseDetail.cardId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.cardNumHint')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputCardNum()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.mobilePhone')"
              name="mobilePhoneId"
              v-if="isWebConfigShowMobilePhoneId"
              :rules="[
                {
                  required: isWebConfigShowEPass,
                  validator: validateIdInfo,
                  trigger: 'blur',
                },
              ]"
            >
              <a-input
                v-model:value="caseDetail.mobilePhoneId"
                :disabled="
                  !isWebConfigEnableMobilePhoneId ||
                  !isEnablePhoneNumberEdit ||
                  (caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus)
                "
                :placeholder="
                  isWebConfigShowMobilePhoneId && isWebConfigShowEPass
                    ? $t('LM001.ePassPhoneNumberHint')
                    : $t('LM001.mobilePhone')
                "
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputMobilePhoneId()"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.myKadId')"
              name="myKadId"
              v-if="isWebConfigShowMyKadId"
            >
              <a-input
                v-model:value="caseDetail.myKadId"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :placeholder="$t('LM001.myKadId')"
                style="width: 90%; margin-right: 8px"
              />
              <MinusCircleOutlined
                class="dynamic-delete-button"
                @click="removeInputMyKadId()"
              />
            </a-form-item>
          </a-card>
          <!-- status management-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.statusManagementArea') }}
            </div>
            <a-form-item
              :label="$t('LM001.eligibilityStatus')"
              name="eligibilityStatus"
              v-if="isShowEligibilityStatus"
            >
              <a-switch v-model:checked="caseDetail.status" />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.validityPeriod')"
              name="validityPeriod"
            >
              <a-date-picker
                v-model:value="caseDetailValidityPeriod"
                :format="birthdayDateFormat"
                :placeholder="$t('LM001.validityPeriodNone')"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :disabled-date="disableValiditydDate"
              />
            </a-form-item>
            <a-form-item
              :label="$t('LM001.ePassStatus')"
              name="ePassStatus"
              v-if="isShowEPassStatus && isWebConfigShowEPass"
            >
              {{ getEPassLocalTime() }}
            </a-form-item>
            <a-form-item :label="$t('LM001.group')" v-if="isWebConfigShowGroup">
              <GroupSelector
                style="width: 250px"
                :groups="groups"
                :isDisabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                v-model:selectedGroups="selectedCaseDetailGroups"
              />
              <!-- <a-select
                :allowClear="true"
                v-model:value="caseDetail.group"
                :placeholder="$t('LM001.pleaseChooseGroup')"
                :disabled="
                  caseDetailMode == 'MODIFY' && !caseDetailEligibilityStatus
                "
                :options="caseDetailGroupOptions"
              >
                <a-select-option
                  v-for="item in caseDetailGroupOptions"
                  :key="item.value"
                  :value="item.value"
                >
                  {{ item.label }}
                </a-select-option>
              </a-select> -->
            </a-form-item>
          </a-card>
          <!-- other-->
          <a-card class="second-card-in-form">
            <div class="second-card-label-in-form">
              {{ $t('LM001.othersArea') }}
            </div>
            <a-form-item
              :label="$t('LM001.phoneNum')"
              name="phoneNumber"
              v-if="isWebConfigShowPhoneNumber"
            >
              <a-input
                v-model:value="caseDetail.phoneNumber"
                :disabled="
                  caseDetailMode == 'INFO' || !isWebConfigEnablePhoneNumber
                "
              />
            </a-form-item>
            <a-form-item :label="$t('LM001.remark')" name="remark">
              <a-textarea
                v-model:value="caseDetail.remark"
                :disabled="caseDetailMode == 'INFO'"
                :rows="4"
                :maxlength="255"
              ></a-textarea>
              <!-- <a-input
              v-model:value="caseDetail.remark"
              :disabled="caseDetailMode == 'INFO'"
            /> -->
            </a-form-item>
          </a-card>
        </a-form>
      </a-modal>
    </div>
    <a-modal v-model:visible="confirmDeleteVisible" title="">
      <template #footer>
        <a-button class="btn-cancel-gray" @click="onClickDeleteCancel">
          {{ $t('LM005.cancel') }}
        </a-button>
        <a-button class="btn-confirm-green" @click="onClickDeleteOk">
          {{ $t('LM005.confirm') }}
        </a-button>
      </template>
      <!-- <p v-text="confirmDeleteMsg"></p> -->
      <div class="text-wrapper" style="white-space: pre-wrap">
        {{ confirmDeleteMsg }}
      </div>
    </a-modal>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onMounted, nextTick, h } from 'vue'
  import { useRouter } from 'vue-router'
  import { message, Modal } from 'ant-design-vue'
  import dayjs, { Dayjs } from 'dayjs'
  import {
    TeamOutlined,
    UserAddOutlined,
    MinusCircleOutlined,
    ExclamationCircleOutlined,
    CheckCircleOutlined,
  } from '@ant-design/icons-vue'
  import { accountExpiredCheck } from '../common/accountExpiredCheck.js'
  import { createVNode } from 'vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { convertMethods } from './composables/convertMethods.js'
  import { useComputed } from './composables/useComputed'
  import { caseDetailMethods } from './composables/caseDetailMethods.js'
  import CaseTable from './CaseTable.vue'
  import GroupSelector from './components/GroupSelector.vue'

  const router = useRouter()
  const { t } = useI18n()
  const store = useStore()

  // 定義響應式數據
  const columns = ref([])
  const isShowCaseDetail = ref(false)
  const caseFormRef = ref(undefined)
  const caseDetailMode = ref('MODIFY') // ['INFO', 'MODIFY', 'CREATE']
  const caseDetail = ref({
    name: '',
    customId: '',
    cardId: '',
    rocId: '',
    mobilePhoneId: '',
    myKadId: '',
    phoneNumber: '',
    height: '',
    heightUnit: 'cm',
    birthday: '',
    gender: '',
    group: '',
    groups: [],
    remark: '',
    validityPeriod: null,
    status: false,
    ePassIssued: '',
  })
  const caseDetailBirthday = ref(Dayjs)
  const caseDetailValidityPeriod = ref(Dayjs)
  const caseDetailLabelCol = ref({ span: 8 })
  const caseDetailWrapperCol = ref({ span: 16 })
  const caseAddUserWrapperCol = ref({ span: 18, offset: 6 })
  const caseDetailRules = ref({
    cardId: [{ validator: validateIdInfo, trigger: 'blur' }],
    customId: [{ validator: validateIdInfo, trigger: 'blur' }],
    rocId: [{ validator: validateIdInfo, trigger: 'blur' }],
    myKadId: [{ validator: validateIdInfo, trigger: 'blur' }],
    gender: [{ required: true, message: '' }],
    height: [
      {
        required: true,
        validator: validateHeight,
        trigger: 'blur',
      },
    ],
    caseDetailBirthday: [{ required: true, message: '' }],
    // birthday: [{ required: true, message: '' }],
  })
  const caseDetailGroupOptions = ref([])
  const isSaveloading = ref(false)
  const confirmDeleteMsg = ref('')
  const confirmDeleteFid = ref(-1)
  const confirmDeleteVisible = ref(false)
  const birthdayDateFormat = ref('YYYY-MM-DD')
  const isEnablePhoneNumberEdit = ref(true)
  const isShowCustomIdCardIdRocIdWarning = ref(false)
  const heightUnit = ref('cm') // 預設公制
  const caseDetailEligibilityStatus = ref(true)
  const filterStatus = ref('Activate') // ['Activate', 'Suspended', 'All']
  const searchType = ref(1) // [1:姓名, 2:群組]
  const searchName = ref('')

  const searchGroupId = ref(0)
  const searchMode = ref('Full') // [Full, Name, Group]
  const caseTableComponent = ref(null)
  const caseGroupTableRefs = ref({})
  const selectedGroups = ref([]) // GroupSelectionModal 元件使用
  const searchGroupActiveKey = ref(null)
  const selectedCaseDetailGroups = ref([]) // CaseDetail 元件使用

  // Vuex action mapping
  const getGroupList = () => store.dispatch('group/getGroupList')
  const setMultipleGroupToCase = (postBody) =>
    store.dispatch('case/setMultipleGroupToCase', postBody)
  const deleteMultipleGroupToCase = (postBody) =>
    store.dispatch('case/deleteMultipleGroupToCase', postBody)
  const createCase = (postBody) => store.dispatch('case/createCase', postBody)
  const modifyCaseInfo = (postBody) =>
    store.dispatch('case/modifyCaseInfo', postBody)
  const deleteCase = (postBody) => store.dispatch('case/deleteCase', postBody)
  const getMeasureThreshold = () =>
    store.dispatch('measureThreshold/getMeasureThreshold')

  const groups = computed(() => store.state.group.groups)
  const cases = computed(() => store.state.case.cases)
  const expiredTs = computed(() => store.state.user.expiredTs)
  const webConfig = computed(() => store.state.user.webConfig)
  const measureThreshold = computed(
    () => store.state.measureThreshold.measureThreshold
  )

  // 定義 methods
  function updateGroupOptions() {
    caseDetailGroupOptions.value = []
    if (!caseDetailGroupOptions.value) {
      return
    }

    groups.value.forEach((item) => {
      caseDetailGroupOptions.value.push({
        value: item.groupId.toString(),
        label: item.title,
      })
    })
  }

  function goToGroupManagement() {
    router.push('/caseManagement/groupManagement')
  }

  const convertCustomId = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].idType === 'CUSTOM_ID') {
          return data[i].idValue
        }
      }
    }
    return ''
  }

  // 定義 watch
  watch(groups, () => {
    updateGroupOptions()
  })

  // 生命週期鉤子
  onMounted(() => {
    accountExpiredCheck(expiredTs.value)
    getMeasureThreshold()
    // getCaseList({ filterFlag: filterStatus.value, page: 1, limit: 10 })
    // getCaseListByName({ filterFlag: filterStatus.value, name: 'd' })
    getGroupList()
    let wishColumn = []
    for (let i = 0; i < webConfig.value.length; i++) {
      if (webConfig.value[i].id === 'subject_management') {
        wishColumn = webConfig.value[i].content || []
        break
      }
    }

    if (wishColumn.length > 0) {
      wishColumn.sort(function (a, b) {
        return a.order - b.order
      })

      columns.value = []
      wishColumn.forEach((col) => {
        if (col?.privilege?.show === true) {
          const colData = mapCaseListColumn(col.id)
          if (colData) {
            columns.value.push(colData)
          }
        }
      })
      // TODO(Dio), 目前權限設定沒有此欄位，先放到最後
      const colEnd = mapCaseListColumn('end')
      if (colEnd) {
        columns.value.push(colEnd)
      }
      const colState = mapCaseListColumn('state')
      if (colState) {
        columns.value.push(colState)
      }
      if (isWebConfigShowEPass.value === true) {
        const colEpass = mapCaseListColumn('ePassIssued')
        if (colEpass) {
          columns.value.push(colEpass)
        }
      }

      if (columns.value.length > 0) {
        columns.value.push({
          title: '',
          dataIndex: 'operation',
          key: 'operation',
          width: '50px',
        })
      }
    }
  })

  const clickCreateCase = () => {
    caseDetailMode.value = 'CREATE'
    isEnablePhoneNumberEdit.value = true
    isShowCaseDetail.value = true
    caseDetail.value = {}
    if (caseFormRef.value) {
      caseFormRef.value.clearValidate()
    }
    caseDetailValidityPeriod.value = null
    caseDetailBirthday.value = null
    isShowCustomIdCardIdRocIdWarning.value = true
    selectedCaseDetailGroups.value = []
  }
  const clickStartModifyMode = () => {
    caseDetailMode.value = 'MODIFY'
    caseFormRef.value.clearValidate()
    isShowCustomIdCardIdRocIdWarning.value = false
    //console.log('clickStartModifyMode')
  }
  const clickActivateSuspend = async (record) => {
    try {
      const patchBody = {
        subjects: [
          {
            fid: record.fid,
            ids: [
              {
                from: {
                  idType: record.id[0].idType,
                  idValue: record.id[0].idValue,
                },
                to: [],
              },
            ],
          },
        ],
      }

      record.id.forEach((idItem) => {
        patchBody.subjects[0].ids[0].to.push({
          idType: idItem.idType,
          idValue: idItem.idValue,
        })
      })

      // ACTIVATED, SUSPENDED
      if (record.state === 'ACTIVATED') {
        patchBody.subjects[0].activate = false
      } else {
        patchBody.subjects[0].activate = true
      }

      isSaveloading.value = true
      await modifyCaseInfo(patchBody)
      callCaseTableFetchData()
      isSaveloading.value = false
    } catch (e) {
      console.log('error', e)
    }
    console.log('clickActivateSuspend')
  }
  const clickDeleteCase = (record) => {
    confirmDeleteMsg.value =
      t('LM001.confirmDeleteMessage') && record.name
        ? t('LM001.confirmDeleteMessage').replace(
            /REPLACE-USER-NAME/,
            record.name.toString()
          )
        : ''
    confirmDeleteFid.value = record.fid
    confirmDeleteVisible.value = true

    // let self = this
    // this.$confirm({
    //   //title: t('LM005.confirmDelete'),
    //   content: '是否確認刪除@USER-NAME？<br>刪除後量測紀錄也將一併刪除。',
    //   // content:
    //   //   t('LM001.confirmDeleteMessage') && record.name
    //   //     ? t('LM001.confirmDeleteMessage').replace(
    //   //         /@USER-NAME/,
    //   //         record.name.toString()
    //   //       )
    //   //     : '',
    //   okText: t('LM005.confirm'),
    //   okType: 'danger',
    //   cancelText: t('LM005.cancel'),
    //   async onOk() {
    //     await self.deleteCase(record.fid)
    //     //message.info(fid)
    //   },
    //   onCancel() {
    //     //console.log('cancel')
    //   },
    // })
  }
  const onClickDeleteOk = async () => {
    if (confirmDeleteFid.value > 0) {
      await deleteCase(confirmDeleteFid.value)
      confirmDeleteFid.value = -1
    }
    confirmDeleteVisible.value = false
    //message.info('delete test')
  }
  const onClickDeleteCancel = () => {
    confirmDeleteFid.value = -1
    confirmDeleteVisible.value = false
  }
  const clickCancelModify = () => {
    // isShowCaseDetail.value = false
    // caseDetailMode.value = 'INFO'
    // caseDetail.value = {}
    caseDetailMode.value = 'MODIFY'
    isShowCaseDetail.value = false
    console.log('clickCancelModify')
  }
  const validateIdInfo = () => {
    if (
      !caseDetail.value.customId &&
      !caseDetail.value.cardId &&
      !caseDetail.value.rocId &&
      !caseDetail.value.mobilePhoneId &&
      !caseDetail.value.myKadId
    ) {
      isShowCustomIdCardIdRocIdWarning.value = true
      return Promise.reject('')
      // return Promise.reject(t('LM001.pleaseInputCustomIdCardIdRocId'))
    } else if (isWebConfigShowEPass.value && !caseDetail.value.mobilePhoneId) {
      isShowCustomIdCardIdRocIdWarning.value = true
      return Promise.reject('')
    } else {
      isShowCustomIdCardIdRocIdWarning.value = false
      return Promise.resolve()
    }
  }
  const validateHeight = () => {
    const re = /^\d'\s?(\d{1,2}")?$/
    if (
      measureThreshold.value?.stature?.unit === 'ft&in' &&
      re.test(caseDetail.value.height) === false
    ) {
      return Promise.reject('')
    } else if (
      measureThreshold.value?.stature?.unit !== 'ft&in' &&
      isNaN(Number(caseDetail.value.height))
    ) {
      return Promise.reject('')
    } else {
      return Promise.resolve()
    }
  }
  const clickSaveModify = async () => {
    try {
      // caseFormRef.value = this.$refs.caseFormRef
      await caseFormRef.value.validate()

      if (cases.value[caseDetail.value.idx].id.length <= 0) {
        return
      }

      const patchBody = {
        subjects: [
          {
            fid: caseDetail.value.fid,
            name: caseDetail.value.name,
            phoneNumber: caseDetail.value.phoneNumber,
            // ids: [
            //   {
            //     from: {
            //       idType: 'CUSTOM_ID',
            //       idValue: this.convertCustomId(
            //         cases.value[caseDetail.value.idx].id
            //       ),
            //     },
            //     to: [
            //       {
            //         idType: 'CUSTOM_ID',
            //         idValue: caseDetail.value.customId,
            //       },
            //     ],
            //   },
            // ],
            ids: [
              {
                from: {
                  idType: cases.value[caseDetail.value.idx].id[0].idType,
                  idValue: cases.value[caseDetail.value.idx].id[0].idValue,
                },
                to: [],
              },
            ],
            groupId: null,
          },
        ],
      }
      if (caseDetail.value.customId) {
        patchBody.subjects[0].ids[0].to.push({
          idType: 'CUSTOM_ID',
          idValue: caseDetail.value.customId,
        })
      }
      if (caseDetail.value.cardId) {
        patchBody.subjects[0].ids[0].to.push({
          idType: 'NFC_CARD_NUMBER',
          idValue: caseDetail.value.cardId,
        })
      }
      if (caseDetail.value.rocId) {
        patchBody.subjects[0].ids[0].to.push({
          idType: 'ROC_ID',
          idValue: caseDetail.value.rocId,
        })
      }
      if (caseDetail.value.mobilePhoneId) {
        patchBody.subjects[0].ids[0].to.push({
          idType: 'MOBILE_PHONE',
          idValue: caseDetail.value.mobilePhoneId,
        })
      }
      if (caseDetail.value.myKadId) {
        patchBody.subjects[0].ids[0].to.push({
          idType: 'MY_KAD_ID',
          idValue: caseDetail.value.myKadId,
        })
      }
      if (caseDetail.value.group) {
        let groupId = caseDetail.value.group
        if (typeof caseDetail.value.group === 'string') {
          groupId = parseInt(caseDetail.value.group, 10)
        }
        patchBody.subjects[0].groupId = groupId
      }
      // if (caseDetail.value.birthday) {
      //   patchBody.subjects[0].birthday = dayjs(
      //     caseDetail.value.birthday
      //   ).format('YYYY-MM-DD')
      // }
      if (caseDetailBirthday.value) {
        patchBody.subjects[0].birthday =
          caseDetailBirthday.value.format('YYYY-MM-DD')
      }
      if (caseDetail.value.gender) {
        patchBody.subjects[0].gender = caseDetail.value.gender
      }
      if (caseDetail.value.height) {
        if (heightUnit.value === 'cm') {
          const iHeight = parseInt(caseDetail.value.height, 10)
          if (isNaN(iHeight) === false) {
            patchBody.subjects[0].height = iHeight
            patchBody.subjects[0].heightUnit = heightUnit.value
          }
        } else if (heightUnit.value == 'ft&in') {
          patchBody.subjects[0].height = caseDetail.value.height
            .replace('"', '')
            .replace("'", '&')
          patchBody.subjects[0].heightUnit = heightUnit.value
        }
      }
      if (caseDetail.value.remark) {
        patchBody.subjects[0].remark = caseDetail.value.remark
      }
      // if (caseDetail.value.validityPeriod) {
      //   patchBody.subjects[0].end = dayjs(caseDetail.value.validityPeriod)
      //     .endOf('day')
      //     .unix()
      // } else {
      //   patchBody.subjects[0].end = null
      // }

      if (caseDetailValidityPeriod.value) {
        patchBody.subjects[0].end = caseDetailValidityPeriod.value
          .endOf('day')
          .unix()
      } else {
        patchBody.subjects[0].end = null
      }

      // ACTIVATED, SUSPENDED
      if (caseDetail.value.status === true) {
        patchBody.subjects[0].activate = true
      } else {
        patchBody.subjects[0].activate = false
      }

      isSaveloading.value = true
      const respData = await modifyCaseInfo(patchBody)
      if (respData?.ok === true) {
        if (caseDetail.value.groups && caseDetail.value.groups.length > 0) {
          await deleteMultipleGroupToCase({
            idType: patchBody.subjects[0].ids[0].to[0].idType,
            idValue: patchBody.subjects[0].ids[0].to[0].idValue,
            groupIds: caseDetail.value.groups.map((item) => {
              return item.id
            }),
          })
        }

        await setMultipleGroupToCase({
          idType: patchBody.subjects[0].ids[0].to[0].idType,
          idValue: patchBody.subjects[0].ids[0].to[0].idValue,
          groupIds: selectedCaseDetailGroups.value.map((item) => {
            return item.groupId
          }),
        })
      }

      callCaseTableFetchData()
      isSaveloading.value = false
      caseDetailMode.value = 'MODIFY'
      isShowCaseDetail.value = false
      caseDetail.value = {}
    } catch (e) {
      console.log('error', e)
    }
    console.log('clickSaveModify')
  }
  const clickSaveCreate = async () => {
    try {
      // caseFormRef.value = this.$refs.caseFormRef
      await caseFormRef.value.validate()

      const postBody = {
        subjects: [
          {
            name: caseDetail.value.name,
            phoneNumber: caseDetail.value.phoneNumber,
            id: [],
          },
        ],
      }
      if (caseDetail.value.customId) {
        postBody.subjects[0].id.push({
          idType: 'CUSTOM_ID',
          idValue: caseDetail.value.customId,
        })
      }
      if (caseDetail.value.cardId) {
        postBody.subjects[0].id.push({
          idType: 'NFC_CARD_NUMBER',
          idValue: caseDetail.value.cardId,
        })
      }
      if (caseDetail.value.rocId) {
        postBody.subjects[0].id.push({
          idType: 'ROC_ID',
          idValue: caseDetail.value.rocId,
        })
      }
      if (caseDetail.value.mobilePhoneId) {
        postBody.subjects[0].id.push({
          idType: 'MOBILE_PHONE',
          idValue: caseDetail.value.mobilePhoneId,
        })
      }
      if (caseDetail.value.myKadId) {
        postBody.subjects[0].id.push({
          idType: 'MY_KAD_ID',
          idValue: caseDetail.value.myKadId,
        })
      }

      if (postBody.subjects[0].id.length <= 0) {
        return
      }

      if (caseDetail.value.group) {
        let groupId = caseDetail.value.group
        if (typeof caseDetail.value.group === 'string') {
          groupId = parseInt(caseDetail.value.group, 10)
        }
        postBody.subjects[0].groupId = groupId
      }
      if (caseDetailBirthday.value) {
        postBody.subjects[0].birthday =
          caseDetailBirthday.value.format('YYYY-MM-DD')
      }
      if (caseDetail.value.gender) {
        postBody.subjects[0].gender = caseDetail.value.gender
      }
      if (caseDetail.value.height) {
        if (heightUnit.value === 'cm') {
          const iHeight = parseInt(caseDetail.value.height, 10)
          if (isNaN(iHeight) === false) {
            postBody.subjects[0].height = iHeight
            postBody.subjects[0].heightUnit = heightUnit.value
          }
        } else if (heightUnit.value == 'ft&in') {
          postBody.subjects[0].height = caseDetail.value.height
            .replace('"', '')
            .replace("'", '&')
          postBody.subjects[0].heightUnit = heightUnit.value
        }
      }
      if (caseDetail.value.remark) {
        postBody.subjects[0].remark = caseDetail.value.remark
      }

      if (caseDetailValidityPeriod.value) {
        postBody.subjects[0].end = caseDetailValidityPeriod.value
          .endOf('day')
          .unix()
      }

      // if (caseDetailValidityPeriod.value) {
      //   postBody.subjects[0].end = caseDetailValidityPeriod.value
      //     .endOf('day')
      //     .unix()
      // } else {
      //   postBody.subjects[0].end = null
      // }

      // ACTIVATED, SUSPENDED
      // if (caseDetail.value.status === true) {
      //   postBody.subjects[0].state = true
      // } else {
      //   postBody.subjects[0].state = false
      // }

      console.log(postBody.subjects[0])

      isSaveloading.value = true
      const respData = await createCase(postBody)
      if (respData?.ok === true && respData?.subjectIds) {
        await setMultipleGroupToCase({
          idType: respData?.subjectIds[0]?.ids[0]?.idType,
          idValue: respData?.subjectIds[0]?.ids[0]?.idValue,
          groupIds: selectedCaseDetailGroups.value.map((item) => {
            return item.groupId
          }),
        })
      }
      callCaseTableFetchData()
      isSaveloading.value = false

      isShowCaseDetail.value = false
      caseDetailMode.value = 'MODIFY'
      caseDetail.value = {}
    } catch (e) {
      console.log('error', e)
    }
    console.log('clickSaveCreate')
  }
  const clickOpenCaseDetail = (fid) => {
    console.log('clickOpenCaseDetail fid:', fid)
    caseDetailMode.value = 'MODIFY'
    isEnablePhoneNumberEdit.value = true

    isShowCustomIdCardIdRocIdWarning.value = false

    const idx = cases.value.findIndex((element) => {
      if (element.fid === parseInt(fid, 10)) {
        return true
      } else {
        return false
      }
    })
    if (idx >= 0) {
      caseDetail.value = {}
      caseDetail.value.idx = idx
      caseDetail.value.fid = cases.value[idx].fid
      caseDetail.value.name = cases.value[idx].name
      caseDetail.value.customId = convertCustomId(cases.value[idx].id)

      caseDetail.value.cardId = convertCardId(cases.value[idx].id)

      caseDetail.value.rocId = convertRocId(cases.value[idx].id)

      caseDetail.value.mobilePhoneId = convertMobilePhoneId(cases.value[idx].id)
      if (isWebConfigShowEPass.value && caseDetail.value.mobilePhoneId) {
        isEnablePhoneNumberEdit.value = false
      }

      caseDetail.value.myKadId = convertMyKadId(cases.value[idx].id)

      caseDetail.value.phoneNumber = cases.value[idx].phoneNumber
      if (cases.value[idx].group) {
        caseDetail.value.group = cases.value[idx].group.id.toString()
      } else {
        caseDetail.value.group = ''
      }

      if (cases.value[idx].groups) {
        caseDetail.value.groups = cases.value[idx].groups
        selectedCaseDetailGroups.value = cases.value[idx].groups.map((item) => {
          return {
            groupId: item.id,
            title: item.title,
          }
        })
      } else {
        caseDetail.value.groups = []
        selectedCaseDetailGroups.value = []
      }

      if (cases.value[idx].gender) {
        caseDetail.value.gender = cases.value[idx].gender
      }
      if (cases.value[idx].birthday) {
        caseDetail.value.birthday = cases.value[idx].birthday
        caseDetailBirthday.value = dayjs(
          cases.value[idx].birthday,
          birthdayDateFormat
        )
      } else {
        caseDetailBirthday.value = null
      }
      if (cases.value[idx].height) {
        if (measureThreshold.value?.stature?.unit === 'ft&in') {
          caseDetail.value.height =
            cases.value[idx].height.replace('&', "'") + '"'
        } else {
          caseDetail.value.height = cases.value[idx].height + ''
        }
      }
      if (cases.value[idx].remark) {
        caseDetail.value.remark = cases.value[idx].remark
      }
      if (cases.value[idx].end) {
        caseDetail.value.validityPeriod = cases.value[idx].end
        caseDetailValidityPeriod.value = dayjs(
          caseDetail.value.validityPeriod,
          birthdayDateFormat
        )
      } else {
        caseDetail.value.validityPeriod = null
        caseDetailValidityPeriod.value = null
      }

      // ACTIVATED, SUSPENDED
      if (cases.value[idx]?.state === 'ACTIVATED') {
        caseDetail.value.status = true
        caseDetail.value.EligibilityStatus = true
      } else {
        caseDetail.value.status = false
        caseDetail.value.EligibilityStatus = false
      }

      if (cases.value[idx]?.ePassIssued) {
        caseDetail.value.ePassIssued = cases.value[idx].ePassIssued
      } else {
        caseDetail.value.ePassIssued = ''
      }

      isShowCaseDetail.value = true
    } else {
      message.info('Data not found')
    }
  }
  const mapCaseListColumn = (id) => {
    let retData = {}
    switch (id) {
      case 'id':
        retData = {
          title: 'id',
          dataIndex: 'fid',
          key: 'fid',
          width: '100px',
          className: 'column-title-center',
        }
        return retData
      case 'name':
        retData = {
          title: t('LM001.name'),
          dataIndex: 'name',
          key: 'name',
          width: '200px',
          className: 'column-title-center',
        }
        return retData
      case 'cid':
        retData = {
          title: t('LM001.idCode'),
          dataIndex: 'id',
          key: 'cid',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      case 'my_kad_id':
        retData = {
          title: t('LM001.myKadId'),
          dataIndex: 'id',
          key: 'my_kad_id',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      case 'mobile_otp':
        retData = {
          title: t('LM001.mobilePhone'),
          dataIndex: 'id',
          key: 'mobile_otp',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      case 'nfc_no':
        retData = {
          title: t('LM001.cardNum'),
          dataIndex: 'id',
          key: 'nfc_no',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      case 'mobile':
        retData = {
          title: t('LM001.phoneNum'),
          dataIndex: 'phoneNumber',
          key: 'phoneNumber',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      case 'roc_id':
        retData = {
          title: t('LM001.rocId'),
          dataIndex: 'id',
          key: 'roc_id',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      // case 'group':
      //   retData = {
      //     title: t('LM001.group'),
      //     dataIndex: 'group',
      //     key: 'group',
      //     width: '100px',
      //     className: 'column-title-center',
      //     slots: {
      //       customRender: 'groupTmp',
      //     },
      //   }
      //   return retData
      case 'modified':
        retData = {
          title: t('LM001.modifiedTime'),
          dataIndex: 'updated',
          key: 'updated',
          width: '130px',
          className: 'column-title-center',
        }
        return retData
      case 'height':
        retData = {
          title: t('LM001.height'),
          dataIndex: 'height',
          key: 'height',
          width: '80px',
          className: 'column-title-center',
        }
        return retData
      case 'birthday':
        retData = {
          title: t('LM001.birthday'),
          dataIndex: 'birthday',
          key: 'birthday',
          width: '130px',
          className: 'column-title-center',
        }
        return retData
      case 'gender':
        retData = {
          title: t('LM001.gender'),
          dataIndex: 'gender',
          key: 'gender',
          width: '80px',
          className: 'column-title-center',
        }
        return retData
      case 'remark':
        retData = {
          title: t('LM001.remark'),
          dataIndex: 'remark',
          key: 'remark',
          width: '200px',
          className: 'column-title-center',
        }
        return retData
      case 'end':
        retData = {
          title: t('LM001.validityPeriod'),
          dataIndex: 'end',
          key: 'end',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      // case 'state':
      //   retData = {
      //     title: t('LM001.eligibilityStatus'),
      //     dataIndex: 'state',
      //     key: 'state',
      //     width: '110px',
      //     className: 'column-title-center',
      //   }
      //   return retData
      case 'ePassIssued':
        retData = {
          title: t('LM001.ePassStatus'),
          dataIndex: 'ePassIssued',
          key: 'ePassIssued',
          width: '150px',
          className: 'column-title-center',
        }
        return retData
      default:
        return null
    }
  }
  const removeInputMobilePhoneId = () => {
    if (
      caseDetailMode.value == 'MODIFY' &&
      !caseDetail.value.EligibilityStatus
    ) {
      return
    }
    if (isEnablePhoneNumberEdit.value === false) {
      Modal.confirm({
        title: t('LM001.deleteEpassPhoneNumberTitle'),
        icon: createVNode(ExclamationCircleOutlined),
        content: t('LM001.deleteEpassPhoneNumberWarning'),
        okText: t('LM005.confirm'),
        okType: 'danger',
        cancelText: t('LM005.cancel'),
        onOk() {
          isEnablePhoneNumberEdit.value = true
          caseDetail.value.mobilePhoneId = ''
        },
        onCancel() {},
      })
    } else {
      caseDetail.value.mobilePhoneId = ''
    }
  }

  const getHeightUnit = () => {
    if (measureThreshold.value?.stature?.unit === 'ft&in') {
      // ft&in
      heightUnit.value = 'ft&in'
      return t('MS005.ft')
    } else {
      // 預設公制
      heightUnit.value = 'cm'
      return t('MS005.cm')
    }
  }
  const getHeightPlaceholder = () => {
    if (measureThreshold.value?.stature?.unit === 'ft&in') {
      return '5\'7"'
    } else {
      // 預設公制
      return '170'
    }
  }
  const onFilter = async (filter) => {
    filterStatus.value = filter
  }
  const disableValiditydDate = (current) => {
    const maxDate = dayjs(expiredTs.value).endOf('day')
    const today = dayjs().startOf('day')
    return current && (current <= today || current > maxDate)
  }

  const onClickSearch = async () => {
    if (searchType.value === 1 && searchName.value !== '') {
      // By Name
      searchMode.value = 'Name'
    } else if (searchType.value === 2 && selectedGroups.value?.length > 0) {
      // By Group
      searchGroupId.value = toNumber(selectedGroups.value[0].groupId)
      searchMode.value = 'Group'
      if (selectedGroups.value.length > 0) {
        searchGroupActiveKey.value = selectedGroups.value[0].groupId // 設置第一個 groupId 為 activeKey
      }
    } else {
      // By All
      searchMode.value = 'Full'
    }
    console.log('searchType ', searchType.value)
    console.log('searchName ', searchName.value)
    console.log('selectedGroups ', selectedGroups.value)
    console.log('searchMode ', searchMode.value)

    await nextTick() // 等待 Vue 更新完成
    callCaseTableFetchData(true)
  }

  const handleGroupCollapseChange = (key) => {
    if (key) {
      searchGroupId.value = toNumber(key)
      callCaseTableFetchData()
      console.log(`Collapse panel with groupId ${key} is opened.`)
    } else {
      searchGroupId.value = toNumber(selectedGroups.value[0]?.groupId)
        ? toNumber(selectedGroups.value[0]?.groupId)
        : 0
      console.log('All collapse panels are closed.')
    }
  }

  const callCaseTableFetchData = (isPageReset = false) => {
    if (searchMode.value === 'Group') {
      const caseTableRef = caseGroupTableRefs.value[searchGroupId.value] // 獲取對應的 CaseTable ref
      if (caseTableRef) {
        caseTableRef.fetchCaseData(isPageReset)
      }
    } else if (caseTableComponent.value) {
      caseTableComponent.value.fetchCaseData(isPageReset)
    }
  }

  // 動態設置 ref
  const setGroupCaseTableRef = (el, groupId) => {
    if (el) {
      caseGroupTableRefs.value[groupId] = el // 將 ref 存儲到物件中，以 groupId 為 key
    }
  }

  function toNumber(value) {
    if (typeof value === 'number') return value // 直接回傳數字
    const num = Number(value)
    return isNaN(num) ? 0 : num // 如果轉換失敗則回傳 0
  }

  // 定義 computed 屬性
  const caseInfoModalTitle = computed(() => {
    if (caseDetailMode.value === 'INFO') {
      return t('LM001.caseInfo')
    } else if (caseDetailMode.value === 'MODIFY') {
      return t('LM001.editInfo')
    } else if (caseDetailMode.value === 'CREATE') {
      return t('LM001.caseInfo')
    } else {
      return t('LM001.caseInfo')
    }
  })

  const {
    isShowLinkToMeasurementsPeriod,
    isWebConfigEnableName,
    isWebConfigEnableCustomId,
    isWebConfigEnableCardId,
    isWebConfigEnableRocId,
    isWebConfigEnableMobilePhoneId,
    isWebConfigEnableMyKadId,
    isWebConfigEnablePhoneNumber,
    isWebConfigEnableGroup,
    isWebConfigShowName,
    isWebConfigShowCustomId,
    isWebConfigShowCardId,
    isWebConfigShowRocId,
    isWebConfigShowMobilePhoneId,
    isWebConfigShowMyKadId,
    isWebConfigShowPhoneNumber,
    isWebConfigShowGroup,
    isWebConfigShowEPass,
    isEnableCreateCase,
    isEnableGroupManagement,
    isEnableCaseEdit,
    isEnableCaseDelete,
    isShowEligibilityStatus,
    isShowEPassStatus,
  } = useComputed(webConfig, caseDetailMode)

  const {
    convertCardId,
    convertRocId,
    convertMobilePhoneId,
    convertMyKadId,
    convertTimeFormat,
    convertTimeFormatType2,
    convertEpass,
    convertGender,
    convertRemark,
    convertHeight,
    convertState,
  } = convertMethods(t, measureThreshold)

  const {
    removeInputIdCode,
    removeInputRocId,
    removeInputCardNum,
    removeInputMyKadId,
    getEPassLocalTime,
  } = caseDetailMethods(t, caseDetailMode, caseDetail)
</script>
<style scoped lang="less">
  th.column-title-center.ant-table-row-cell-break-word,
  td.column-title-center.ant-table-row-cell-break-word {
    text-align: center !important;
  }
  .ant-table-striped :deep(.table-striped) td {
    background-color: #f5f5f5;
  }

  .clear-fix-float() {
    content: '';
    display: block;
    clear: both;
  }

  .case-list-container {
    background-color: #e5e5e5;
    padding-top: 24px;
    padding-bottom: 24px;

    .case-list-area {
      //margin-top: 24px;
      margin-left: 32px;
      margin-right: 32px;
      background: #ffffff;
      box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.24);
      border-radius: 8px;

      .case-list-top {
        height: 75px;
        .clear-fix-float();

        .example-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .import-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .new-case-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .group-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
        .filter-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
          .check-btn {
            // margin-top: 50px;
            margin-left: 10px;
            // width: 135px;
            height: 32px;
            border-radius: 4px;
            background: #4ebaa8;
            text-align: center;
            color: var(--white-white, #fff);
            /* body/sans-serif/desktop/default/body-s-default */
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .uncheck-btn {
            // margin-top: 50px;
            margin-left: 10px;
            // width: 135px;
            height: 32px;
            border-radius: 4px;
            background: #bdbdbd;
            text-align: center;
            color: var(--white-white, #fff);
            /* body/sans-serif/desktop/default/body-s-default */
            font-family: 'Source Sans Pro';
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
        .search-btn {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
          .confirm-button {
            color: white;
            background: #4dbaa7;
            border-radius: 6px;
            &:focus {
              -webkit-appearance: none;
              outline: none;
              box-shadow: none;
            }
          }
        }
        .search-type {
          min-width: 110px;
          height: 32px;
          margin-top: 25px;
          margin-right: 10px;
          float: right;
        }
        .search-condition {
          min-width: 110px;
          height: 32px;
          margin-top: 20px;
          margin-right: 10px;
          float: right;
        }
      }

      .case-list-table {
        .clear-fix-float();
      }
    }
  }

  //.case-case-detail-modal {
  .first-card-in-form {
    // margin-top: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
  .second-card-in-form {
    margin-top: 20px;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.15);
    border-radius: 5px;
  }
  .first-card-label-flex-container {
    display: flex;
  }
  .first-card-warning {
    // padding-top: 10px;
    height: 20px;
    padding-left: 10px;
    margin-bottom: 10px;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #ea6363;
  }
  .card-label-in-form {
    width: max-content;
    margin-left: -20px;
    // margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background: #4dbaa7;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    /* identical to box height */

    // display: flex;
    // align-items: center;
    text-align: right;

    color: #ffffff;
  }
  .second-card-label-in-form {
    width: max-content;
    margin-left: -20px;
    margin-bottom: 20px;
    padding-left: 15px;
    padding-right: 15px;
    background: #4dbaa7;
    font-family: 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 29px;
    /* identical to box height */

    // display: flex;
    // align-items: center;
    text-align: right;

    color: #ffffff;
  }
  .btn-confirm-green {
    width: 135px;
    background: #4ebaa8;
    border-radius: 4px;
  }
  .btn-cancel-gray {
    width: 135px;
    background: #bdbdbd;
    border-radius: 4px;
  }
  .dynamic-delete-button {
    cursor: pointer;
    position: relative;
    top: 4px;
    font-size: 24px;
    color: #999;
    transition: all 0.3s;
  }
  //}
</style>
