import { render } from "./CaseTable.vue?vue&type=template&id=21df614e&scoped=true"
import script from "./CaseTable.vue?vue&type=script&setup=true&lang=js"
export * from "./CaseTable.vue?vue&type=script&setup=true&lang=js"

import "./CaseTable.vue?vue&type=style&index=0&id=21df614e&scoped=true&lang=less"
script.render = render
script.__scopeId = "data-v-21df614e"
/* hot reload */
if (module.hot) {
  script.__hmrId = "21df614e"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('21df614e', script)) {
    api.reload('21df614e', script)
  }
  
  module.hot.accept("./CaseTable.vue?vue&type=template&id=21df614e&scoped=true", () => {
    api.rerender('21df614e', render)
  })

}

script.__file = "src/views/case/CaseTable.vue"

export default script