<template>
  <div>
    <a-table
      class="ant-table-striped"
      :dataSource="cases"
      :columns="columns"
      :pagination="pagination"
      rowKey="fid"
      :scroll="{ x: 1500 }"
      :rowClassName="tableListRowClassName"
      @change="handleTableChange"
    >
      <template #bodyCell="{ column, record, text }">
        <template v-if="column?.dataIndex === 'name'">
          <template v-if="record?.groups?.length > 0">
            <a-popover placement="rightTop">
              <template #content>
                <div v-for="(group, index) in record?.groups" :key="index">
                  {{ group.title }}
                </div>
              </template>
              <a-button
                type="text"
                block
                class="name-area"
                style="white-space: normal; word-break: break-word"
              >
                {{ record.name }}
              </a-button>
            </a-popover>
          </template>
          <template v-else>
            <a-button
              type="text"
              block
              class="name-area"
              style="white-space: normal; word-break: break-word"
            >
              {{ record.name }}
            </a-button>
          </template>
        </template>

        <template v-if="column?.dataIndex === 'id' && column?.key === 'cid'">
          <span>
            {{ convertCustomId(text) }}
          </span>
        </template>

        <template
          v-if="column?.dataIndex === 'id' && column?.key === 'my_kad_id'"
        >
          <span>
            {{ convertMyKadId(text) }}
          </span>
        </template>

        <template
          v-if="column?.dataIndex === 'id' && column?.key === 'mobile_otp'"
        >
          <span>
            {{ convertMobilePhoneId(text) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'id' && column?.key === 'nfc_no'">
          <span>
            {{ convertCardId(text) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'id' && column?.key === 'roc_id'">
          <span>
            {{ convertRocId(text) }}
          </span>
        </template>

        <template v-if="column?.dataIndex === 'updated'">
          {{ convertTimeFormat(text) }}
        </template>

        <template v-if="column?.dataIndex === 'height'">
          {{ convertHeight(text) }}
        </template>

        <template v-if="column?.dataIndex === 'gender'">
          {{ convertGender(text) }}
        </template>

        <template v-if="column?.dataIndex === 'name'"></template>

        <template v-if="column?.dataIndex === 'remark'">
          <a-popover title="" trigger="click">
            <template #content>
              <div style="max-width: 200px">
                <p>{{ text }}</p>
              </div>
            </template>
            <a>{{ convertRemark(text) }}</a>
          </a-popover>
        </template>

        <template v-if="column?.dataIndex === 'end'">
          {{ convertTimeFormatType2(text) }}
        </template>

        <template v-if="column?.dataIndex === 'ePassIssued'">
          {{ convertEpass(text) }}
        </template>

        <template v-if="column?.dataIndex === 'state'">
          {{ convertState(text) }}
        </template>

        <template v-if="column?.key === 'operation'">
          <a-dropdown>
            <a class="ant-dropdown-link" @click.prevent>
              <BarsOutlined />
            </a>
            <template #overlay>
              <a-menu>
                <a-menu-item :disabled="isEnableCaseEdit">
                  <div @click="clickOpenCaseDetail(record.fid)">
                    {{ $t('LM001.edit') }}
                  </div>
                </a-menu-item>
                <a-menu-item :disabled="isEnableCaseEdit">
                  <div @click="clickActivateSuspend(record)">
                    {{
                      record.state === 'ACTIVATED'
                        ? $t('LM001.suspend')
                        : $t('LM001.activate')
                    }}
                  </div>
                </a-menu-item>
                <a-menu-item :disabled="isEnableCaseDelete">
                  <div @click="clickDeleteCase(record)">
                    {{ $t('LM001.delete') }}
                  </div>
                </a-menu-item>
                <a-menu-item v-if="isShowLinkToMeasurementsPeriod">
                  <router-link
                    :to="{
                      name: 'MeasurementsPeriod',
                      params: {
                        fid: record.fid,
                      },
                      query: {
                        naviType: 'case',
                      },
                    }"
                  >
                    {{ $t('LM001.personalMeasurements') }}
                  </router-link>
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown>
        </template>
      </template>
    </a-table>
  </div>
</template>

<script setup>
  import { ref, computed, watch, onMounted, defineExpose } from 'vue'
  import { useRouter } from 'vue-router'
  import { BarsOutlined } from '@ant-design/icons-vue'
  import { message, Modal } from 'ant-design-vue'
  import dayjs from 'dayjs'
  import {
    ExclamationCircleOutlined,
    CheckCircleOutlined,
  } from '@ant-design/icons-vue'
  import { useI18n } from 'vue-i18n'
  import { useStore } from 'vuex'
  import { convertMethods } from './composables/convertMethods.js'
  import { useComputed } from './composables/useComputed'

  const router = useRouter()
  const { t } = useI18n()
  const store = useStore()

  // Vuex action mapping
  const getGroupList = () => store.dispatch('group/getGroupList')
  const getCaseList = (postBody) => store.dispatch('case/getCaseList', postBody)
  const getCaseListByName = (postBody) =>
    store.dispatch('case/getCaseListByName', postBody)
  const getCaseListByGroup = (postBody) =>
    store.dispatch('case/getCaseListByGroup', postBody)

  const groups = computed(() => store.state.group.groups)
  const cases = computed(() => store.state.case.cases)
  const caseTotal = computed(() => store.state.case.caseTotal)
  const webConfig = computed(() => store.state.user.webConfig)
  const measureThreshold = computed(
    () => store.state.measureThreshold.measureThreshold
  )

  const caseGroups = []

  const pagination = ref({
    current: 1,
    pageSize: 10,
    total: 0,
  })

  const props = defineProps({
    columns: Array,
    filterStatus: String,
    searchMode: String,
    searchName: String,
    searchGroupId: Number,
  })

  const emit = defineEmits(['openCaseDetail', 'activateSuspend', 'deleteCase'])

  // 定義響應式數據

  // 生命週期鉤子
  onMounted(async () => {
    const respData = await fetchCaseData()
    if (respData?.total >= 0) {
      pagination.value.total = respData.total
    }
  })

  // 定義 methods
  const convertCustomId = (data) => {
    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].idType === 'CUSTOM_ID') {
          return data[i].idValue
        }
      }
    }
    return ''
  }

  const tableListRowClassName = (record, index) => {
    if (record.state === 'SUSPENDED') {
      return 'table-striped'
    }

    return null
  }

  // 定義 computed 屬性
  const {
    isShowLinkToMeasurementsPeriod,
    isEnableCaseEdit,
    isEnableCaseDelete,
  } = useComputed(webConfig)

  const {
    convertCardId,
    convertRocId,
    convertMobilePhoneId,
    convertMyKadId,
    convertTimeFormat,
    convertTimeFormatType2,
    convertEpass,
    convertGender,
    convertRemark,
    convertHeight,
    convertState,
  } = convertMethods(t, measureThreshold)

  const clickOpenCaseDetail = (fid) => {
    emit('openCaseDetail', fid)
  }

  const clickActivateSuspend = (record) => {
    emit('activateSuspend', record)
  }

  const clickDeleteCase = (record) => {
    emit('deleteCase', record)
  }

  const getGroupTitle = (group) => {
    if (group && group.title) {
      return group.title
    }
    return ''
  }

  const getGroups = (group) => {
    if (group) {
      const groupList = []
      groupList.push(group.title)
      return groupList
    }
    return []
  }

  const fetchCaseData = async (isRefresh = false) => {
    console.log('props.searchMode', props.searchMode)
    // 如果 isRefresh 為 true，則將 page 重設為 1
    if (isRefresh) {
      pagination.value.current = 1
    }

    if (props.searchMode === 'Name') {
      return await getCaseListByName({
        name: props.searchName,
        filterFlag: props.filterStatus,
        page: pagination.value.current,
        limit: pagination.value.pageSize,
      })
    } else if (props.searchMode === 'Group') {
      return await getCaseListByGroup({
        group: props.searchGroupId,
        filterFlag: props.filterStatus,
        page: pagination.value.current,
        limit: pagination.value.pageSize,
      })
    } else if (props.searchMode === 'Full') {
      return await getCaseList({
        filterFlag: props.filterStatus,
        page: pagination.value.current,
        limit: pagination.value.pageSize,
      })
    }
  }

  // 處理分頁變化
  const handleTableChange = (pag) => {
    pagination.value.current = pag.current
    pagination.value.pageSize = pag.pageSize
    fetchCaseData()
  }

  watch(caseTotal, () => {
    pagination.value.total = caseTotal.value
  })

  // 監聽 filterStatus 變更時重新獲取資料
  watch(
    () => props.filterStatus,
    (newStatus) => {
      fetchCaseData()
    }
  )

  defineExpose({
    fetchCaseData,
  })
</script>

<style scoped lang="less">
  .name-area:hover {
    background-color: transparent !important;
    border-color: transparent !important;
  }
</style>
