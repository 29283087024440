import { render } from "./GroupSelector.vue?vue&type=template&id=12bfb07a"
import script from "./GroupSelector.vue?vue&type=script&setup=true&lang=js"
export * from "./GroupSelector.vue?vue&type=script&setup=true&lang=js"
script.render = render
/* hot reload */
if (module.hot) {
  script.__hmrId = "12bfb07a"
  const api = __VUE_HMR_RUNTIME__
  module.hot.accept()
  if (!api.createRecord('12bfb07a', script)) {
    api.reload('12bfb07a', script)
  }
  
  module.hot.accept("./GroupSelector.vue?vue&type=template&id=12bfb07a", () => {
    api.rerender('12bfb07a', render)
  })

}

script.__file = "src/views/case/components/GroupSelector.vue"

export default script