<template>
  <div>
    <a-select
      v-model:value="selectedGroupIds"
      style="width: 100%"
      mode="multiple"
      :placeholder="t('LM001.selectedGroup')"
      :max-tag-count="2"
      @focus="handleSearchGroupFocus"
      @change="handleSelectGroupChange"
      :open="false"
      :max-tag-placeholder="maxTagPlaceholderForGroup"
      :tag-render="tagRenderForGroup"
      :disabled="isDisabled"
      :class="{ 'disabled-select': isDisabled }"
    >
      <a-select-option
        v-for="group in groups"
        :key="group.groupId"
        :value="group.groupId"
      >
        {{ group.title }}
      </a-select-option>
    </a-select>
    <GroupSelectionModal
      :visible="isShowGroupSelectionModal"
      :title="t('HR001.chooseGroup')"
      :ok-text="t('LM005.confirm')"
      :groups="groups"
      :selectedGroups="selectedGroups"
      @update:handleGroupSelectionUpdate="handleGroupSelectionUpdate"
      @cancel="handleGroupSelectionCancel"
      @ok="handleGroupSelectionOk"
    />
    <SelectedGroupsModal
      :visible="isShowSelectedGroupsModal"
      :title="t('LM001.selectedGroup')"
      :ok-text="t('LM005.confirm')"
      :selectedGroups="selectedGroups"
      @update:selectedGroups="handleSelectedGroupsUpdate"
      @cancel="handleSelectedGroupCancel"
      @ok="handleSelectedGroupsOk"
    />
  </div>
</template>

<script setup>
  import { defineProps, defineEmits, computed, watch, h, ref } from 'vue'
  import { useI18n } from 'vue-i18n'
  import GroupSelectionModal from './GroupSelectionModal.vue'
  import SelectedGroupsModal from './SelectedGroupsModal.vue'

  const { t } = useI18n()

  const props = defineProps({
    groups: {
      type: Array,
      required: true,
    },
    selectedGroups: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  })

  const emit = defineEmits(['update:selectedGroups'])

  const isShowGroupSelectionModal = ref(false)
  // const selectedGroupIds = ref([]) // a-select 元件使用
  const isShowSelectedGroupsModal = ref(false)

  const handleSearchGroupFocus = () => {
    // console.log('輸入框獲得焦點')
    document.activeElement.blur()
    isShowGroupSelectionModal.value = true
  }

  const handleGroupSelectionUpdate = (selected) => {
    selectedGroups.value = selected
    // 更新 selectedGroupIds
    selectedGroupIds.value = selected.map((group) => group.groupId)
  }

  const handleGroupSelectionCancel = () => {
    isShowGroupSelectionModal.value = false
  }

  const handleGroupSelectionOk = () => {
    isShowGroupSelectionModal.value = false
    console.log('選中的群組:', selectedGroups.value)
  }

  // 當 <a-select> 的選項變化時觸發
  const handleSelectGroupChange = (selectedIds) => {
    selectedGroupIds.value = selectedIds
  }

  // 監聽 selectedGroupIds 的變化，同步到 selectedGroups
  //   watch(selectedGroupIds, (newVal) => {
  //     // 根據 selectedGroupIds 過濾出對應的群組物件
  //     selectedGroups.value = groups.value.filter((group) =>
  //       newVal.includes(group.groupId)
  //     )
  //   })

  const handleSelectedGroupsUpdate = (selected) => {
    selectedGroups.value = selected
    // 更新 selectedGroupIds
    selectedGroupIds.value = selected.map((group) => group.groupId)
  }

  const handleSelectedGroupCancel = () => {
    isShowSelectedGroupsModal.value = false
  }

  const handleSelectedGroupsOk = () => {
    isShowSelectedGroupsModal.value = false
    console.log('更改的群組:', selectedGroups.value)
  }

  const handleSearchGroupMoreClick = () => {
    console.log('點擊更多群組')
    isShowSelectedGroupsModal.value = true
  }

  // 自定義顯示被省略的標籤數量，群組選擇
  const maxTagPlaceholderForGroup = (omittedValues) => {
    return h(
      'span',
      {
        style: { color: '#2F80ED' },
        onClick: (e) => {
          e.stopPropagation()
          handleSearchGroupMoreClick(e)
        },
      },
      `${t('MO001.more')}(${omittedValues.length})`
    )
  }
  const tagRenderForGroup = (props) => {
    // console.log(`tagRenderForGroup`, props)
    const { label, closable, onClose } = props
    const displayLabel =
      label[0]?.children?.length > 3
        ? label[0]?.children?.substring(0, 3) + '...'
        : label[0]
    return (
      <a-tag closable={closable} onClose={onClose}>
        {displayLabel}
      </a-tag>
    )
  }

  // 將 selectedGroups 轉換為 groupId 陣列
  const selectedGroupIds = computed({
    get() {
      return props.selectedGroups.map((group) => group.groupId)
    },
    set(value) {
      // 根據選中的 groupId 陣列，從 groups 中找出對應的完整物件
      const selected = props.groups.filter((group) =>
        value.includes(group.groupId)
      )
      emit('update:selectedGroups', selected)
    },
  })

  const selectedGroups = computed({
    get() {
      return props.selectedGroups
    },
    set(value) {
      // console.log('set selectedGroups', value)
      emit('update:selectedGroups', value)
    },
  })
</script>
