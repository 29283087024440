<template>
  <a-modal
    :visible="visible"
    :ok-text="okText"
    :ok-button-props="{
      style: {
        color: 'white',
        background: '#4dbaa7',
        borderRadius: '6px',
        border: 'none',
      },
    }"
    :cancel-button-props="{ style: { display: 'none' } }"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <template #title>
      <span class="custom-title">{{ title }}</span>
    </template>
    <div class="checkbox-container">
      <a-checkbox-group v-model:value="selectedGroupIds">
        <a-row>
          <a-col
            class="checkbox-item"
            :span="24"
            v-for="group in groups"
            :key="group.groupId"
          >
            <a-checkbox class="custom-checkbox" :value="group.groupId">
              <span style="font-size: 18px">{{ group.title }}</span>
            </a-checkbox>
          </a-col>
        </a-row>
      </a-checkbox-group>
    </div>
  </a-modal>
</template>

<script setup>
  import { ref, watch } from 'vue'

  const props = defineProps({
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: 'Select a Group',
    },
    okText: {
      type: String,
      default: 'Done', // 預設確認按鈕文字
    },
    groups: {
      type: Array,
      required: true,
    },
    selectedGroups: {
      type: Array,
      default: () => [],
    },
  })

  const emit = defineEmits([
    'update:handleGroupSelectionUpdate',
    'cancel',
    'ok',
  ])

  // 選中的群組 ID 列表
  const selectedGroupIds = ref(
    props.selectedGroups.map((group) => group.groupId)
  )

  // 監聽 selectedGroups 的變化，同步到 selectedGroupIds
  watch(
    () => props.selectedGroups,
    (newVal) => {
      selectedGroupIds.value = newVal.map((group) => group.groupId)
    }
  )

  // 取消事件
  const handleCancel = () => {
    emit('cancel')
  }

  // 確認事件
  const handleOk = () => {
    // 根據選中的 groupId，過濾出完整的群組物件
    const selectedGroups = props.groups.filter((group) =>
      selectedGroupIds.value.includes(group.groupId)
    )
    // 將完整的群組物件傳回父元件
    emit('update:handleGroupSelectionUpdate', selectedGroups)
    emit('ok')
  }
</script>

<style scoped>
  .custom-title {
    font-size: 18px; /* 設置 title 字體大小為 16px */
  }
  /* Checkbox 容器（限制最大高度 + 滾動條） */
  .checkbox-container {
    max-height: 500px;
    overflow-y: auto;
    padding-right: 10px;
  }

  /* 讓 Checkbox 每兩個之間有 20px 間距 */
  .checkbox-item {
    margin-bottom: 10px;
  }

  .custom-checkbox :deep(.ant-checkbox-inner) {
    border-color: #4dbaa7; /* 框框顏色 */
    background-color: transparent; /* 背景透明 */
  }

  .custom-checkbox :deep(.ant-checkbox-checked .ant-checkbox-inner) {
    background-color: #4dbaa7; /* 選中時的背景顏色 */
    border-color: #4dbaa7; /* 選中時的框框顏色 */
  }

  .custom-checkbox :deep(.ant-checkbox-checked .ant-checkbox-inner::after) {
    border-color: white; /* 勾勾顏色 */
  }
</style>
