<template>
  <a-modal
    :visible="visible"
    :ok-text="okText"
    :ok-button-props="{
      style: {
        color: 'white',
        background: '#4dbaa7',
        borderRadius: '6px',
        border: 'none',
      },
    }"
    :cancel-button-props="{ style: { display: 'none' } }"
    @cancel="handleCancel"
    @ok="handleOk"
  >
    <template #title>
      <span class="custom-title">{{ title }}</span>
    </template>
    <div class="tag-container">
      <div class="selected-groups-display">
        <a-tag
          v-for="group in selectedGroups"
          :key="group.groupId"
          closable
          @close="handleRemoveGroup(group)"
          class="custom-tag"
        >
          {{ group.title }}
        </a-tag>
      </div>
    </div>
  </a-modal>
</template>

<script setup>
  import { defineProps, defineEmits } from 'vue'

  const props = defineProps({
    visible: {
      type: Boolean,
      required: true,
    },
    title: {
      type: String,
      default: '已選群組',
    },
    okText: {
      type: String,
      default: '確認', // 預設確認按鈕文字
    },
    selectedGroups: {
      type: Array,
      required: true,
    },
  })

  const emit = defineEmits(['update:selectedGroups', 'ok', 'cancel'])

  // 移除群組
  const handleRemoveGroup = (groupToRemove) => {
    const updatedGroups = props.selectedGroups.filter(
      (group) => group.groupId !== groupToRemove.groupId
    )
    // 將更新後的群組列表傳回父元件
    emit('update:selectedGroups', updatedGroups)
  }

  // 取消事件
  const handleCancel = () => {
    emit('cancel')
  }

  // 確認事件
  const handleOk = () => {
    emit('ok')
  }
</script>

<style scoped>
  .custom-title {
    font-size: 18px; /* 設置 title 字體大小為 16px */
  }
  /* tag 容器（限制最大高度 + 滾動條） */
  .tag-container {
    max-height: 500px;
    overflow-y: auto;
    padding-right: 10px;
  }
  .selected-groups-display {
    display: flex;
    flex-wrap: wrap;
    gap: 8px; /* 設置標籤之間的間距 */
  }
  .custom-tag {
    border-radius: 20px; /* 圓弧邊框 */
    font-size: 16px; /* 字體大小 */
    padding-top: 5px;
    padding-bottom: 5px;
    padding-left: 10px;
    padding-right: 5px;
  }
</style>
